import { z } from 'zod'

// ref. zod.dev/ERROR_HANDLING?id=a-working-example
export const customErrorMap: z.ZodErrorMap = (error, ctx) => {
  switch (error.code) {
    case z.ZodIssueCode.invalid_type:
      if (error.received === 'undefined') {
        return { message: 'この項目は必須です' }
      }
      if (error.expected === 'number') {
        return { message: '半角数字で入力してください' }
      }
      break
    case z.ZodIssueCode.invalid_enum_value:
      return { message: 'この項目は必須です' }

    case z.ZodIssueCode.invalid_string:
      if (error.validation === 'email') {
        return { message: 'メールアドレスを正しく入力してください' }
      }
      break

    case z.ZodIssueCode.invalid_date:
      return { message: '有効な日付を入力してください' }

    case z.ZodIssueCode.too_small:
      if (error.type === 'array')
        return {
          message: `${error.minimum}件${
            error.inclusive ? '以上' : 'より多く'
          }入力してください`,
        }
      else if (error.type === 'string')
        return {
          message: `${error.minimum}文字${
            error.inclusive ? '以上で' : 'より多く'
          }入力してください`,
        }
      else if (error.type === 'number')
        return {
          message: `${error.minimum}${
            error.inclusive ? '以上の' : 'より大きい'
          }値を入力してください`,
        }
      break

    case z.ZodIssueCode.too_big:
      if (error.type === 'array')
        return {
          message: `${error.maximum}件${
            error.inclusive ? '以内' : 'より少なく'
          }入力してください`,
        }
      else if (error.type === 'string')
        return {
          message: `${error.maximum}文字${
            error.inclusive ? '以内で' : 'より少なく'
          }入力してください`,
        }
      else if (error.type === 'number')
        return {
          message: `${error.maximum}${
            error.inclusive ? '以内の' : 'より小さい'
          }値で入力してください`,
        }
      break

    case z.ZodIssueCode.custom:
      {
        // produce a custom message using error.params
        // error.params won't be set unless you passed
        // a `params` arguments into a custom validator
        const params = error.params || {}
        if (params.invalidPhoneNumber) {
          return { message: `Bad input: ${params.myField}` }
        }
      }
      break
  }

  // fall back to default message!
  return { message: ctx.defaultError }
}
