/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { startTransition, StrictMode, useEffect } from 'react'

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/remix'
import { hydrateRoot } from 'react-dom/client'
import { z } from 'zod'

import { customErrorMap } from './lib'

Sentry.init({
  dsn: 'https://5d581fb6c2b9917cb077d8ffb5be822e@o1309262.ingest.us.sentry.io/4508976300949504',
  environment: import.meta.env.VITE_ENV_NAME, // 環境名を設定
  tracesSampleRate: 1.0,

  integrations: [
    // eslint-disable-next-line import/namespace
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    // eslint-disable-next-line import/namespace
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  beforeSend(event) {
    // ローカルではイベントを破棄
    if (import.meta.env.MODE === 'development') {
      return null
    }
    return event
  },
})

startTransition(() => {
  z.setErrorMap(customErrorMap)

  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
